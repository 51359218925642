<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="row">
        <div class="col-md-2">
          <label class="d-block" for="">N° Employé</label>
          <input
            id="inlineFormInputName2"
            type="text"
            v-model="searchForm.empNo"
            placeholder=""
            class="mb-3 form-control"
          />
        </div>

        <div class="col-md-2">
          <label class="d-block" for="">Nom</label>
          <input
            id="inlineFormInputName2"
            type="text"
            v-model="searchForm.last_name"
            placeholder=""
            class="mb-3 form-control"
          />
        </div>
        <div class="col-md-2">
          <label class="d-block" for="">Prénom</label>
          <input
            id="inlineFormInputName2"
            type="text"
            v-model="searchForm.first_name"
            placeholder=""
            class="mb-3 form-control"
          />
        </div>
        <div class="col-md-2">
          <label class="d-block" for="">Date d'arrivé</label>
          <input
            id="inlineFormInputName2"
            type="date"
            v-model="searchForm.dateIn"
            placeholder="Bénéficiaire"
            class="mb-3 form-control"
          />
        </div>

        <div class="col-md-2">
          <label class="d-block" for="">Date de départ</label>
          <input
            id="inlineFormInputName2"
            type="date"
            v-model="searchForm.dateOut"
            placeholder="Bénéficiaire"
            class="mb-3 form-control"
          />
        </div>
        <div class="col-md-2">
          <button
            @click="performBookingSearch"
            type="button"
            style="inline-block"
            class="btn w-md btn-primary mt-4"
          >
            Rechercher
          </button>
          <button
            style="inline-block"
            type="button"
            class="btn btn-warning mt-4 ml-2"
            @click="resetSearchForm"
          >
            Vider
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchLifebasesApi } from "@/api/common";

export default {
  import: { fetchLifebasesApi },
  data() {
    return {
      campsList: [],
      searchForm: {
        empNo: "",
        last_name: "",
        first_name: "",
        dateIn: "",
        dateOut: "",
      },
    };
  },
  mounted() {
    this._fetchCampsList();
  },
  methods: {
    _fetchCampsList() {
      fetchLifebasesApi()
        .then((res) => (this.campsList = res.data.original.list))
        .catch(() => {})
        .finally(() => {});
      // bases
    },
    performBookingSearch() {
      if (
        this.searchForm.empNo == "" &&
        this.searchForm.last_name == "" &&
        this.searchForm.first_name == "" &&
        this.searchForm.dateIn == "" &&
        this.searchForm.dateOut == ""
      ) {
        this.$toast.error("Veuillez remplir au moin un champ.");
      } else {
        this.$emit("performPlanningsearch", this.searchForm);
      }
    },

    resetSearchForm() {
      this.searchForm.empNo = "";
      this.searchForm.last_name = "";
      this.searchForm.first_name = "";
      this.searchForm.dateIn = "";
      this.searchForm.dateOut = "";
      this.$emit("resetSearchForm", true);
    },
  },
};
</script>
