<template>
  <div class="row">
    <div class="col-lg-12">
      <search
        @performPlanningsearch="handlePerformplanningsearchEvent"
        @resetSearchForm="handleResetSearchFormEvent"
      />

      <div class="accordion mb-2" role="tablist">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              @click="active = !active"
              style="background:#e5e9f0; color:black !important; border:none !important;"
            >
              <div class="row">
                <div class="col text-left mt-1">
                  <i class="mdi mdi-format-list-bulleted"></i> Liste des
                  Réservations
                </div>
                <div class="col text-right">
                  <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i>
                  <i v-else class="mdi mdi-chevron-down font-size-18"></i>
                </div>
              </div>
            </b-button>
          </b-card-header>
          <b-collapse
            id="accordion-1"
            :visible="active"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body class="shadow-lg">
              <dataset v-slot="{ ds }" :ds-data="plannings.data">
                <div class="row">
                  <div class="col-md-12">
                    <div class="">
                      <table class="table table-hover d-md-table table-striped">
                        <thead>
                          <tr>
                            <th v-for="th in cols" :key="th.name">
                              {{ th.name }}
                            </th>
                          </tr>
                        </thead>
                        <dataset-item tag="tbody">
                          <template #default="{ row }">
                            <tr>
                              <td>
                                <b>{{ row.emp_num }}</b>
                              </td>
                              <td>
                                {{
                                  row.employee ? row.employee.last_name : "N/A"
                                }}
                              </td>
                              <td>
                                {{
                                  row.employee ? row.employee.first_name : "N/A"
                                }}
                              </td>
                              <td>{{ row.date_from }}</td>
                              <td>{{ row.date_to }}</td>

                              <td>
                                <a v-if="row.employee.checkin"
                                  class="text-primary"
                                  style="cursor: pointer;"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Check In"
                                  data-original-title="Check In"
                                  @click="ShowCheckinModal(row.employee)"
                                  >Check In
                                </a>
                                <a v-else-if="row.employee.checkout"
                                  class="text-primary"
                                  style="cursor: pointer;"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Check Out"
                                  data-original-title="Check Out"
                                  @click="ShowCheckOutModal(row.employee)"
                                  >Check Out</a
                                >
                                <span v-else> N/A </span>
                              </td>
                            </tr>
                          </template>
                        </dataset-item>
                      </table>
                    </div>
                  </div>
                </div>
                <div
                  class="d-flex flex-md-row flex-column justify-content-between align-items-center"
                >
                  <!-- <dataset-info class="mb-2 mb-md-0" /> -->
                  <dataset-show
                    class="mb-2"
                    v-model="defaultShow"
                    @changed="onShowChange"
                    :ds-show-entries="defaultShow"
                  />
                  <pagination
                    :data="plannings"
                    :limit="defaultShow"
                    class="float-right"
                    @pagination-change-page="refreshplanningsTable"
                  />
                </div>
              </dataset>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>

    <Modal
      v-model="showNewBookingModal"
      :title="`Ajouter une réservation `"
    >
      <div class="row">
        <div class="col-lg-12">
          <b-form>
            <div class="row">
              <div class="col-md-4">
                <label for="">Employé</label>
                <input type="text" disabled class="form-control" v-model="newBooking.emp">
              </div>

              <div class="col-md-4">
                <label for="">De</label>
                <input type="text" disabled class="form-control" v-model="newBooking.from">
              </div>

              <div class="col-md-4">
                <label for="">à</label>
                <input type="text" disabled class="form-control" v-model="newBooking.to">
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-4">
                <label for="">Site</label>
                <multiselect
                  v-model="newBooking.campId"
                  :searchable="true"
                  track-by="id"
                  open-direction="bottom"
                  label="name"
                  :options="camps"
                  @input="onCampChange"
                  :class="{'is-invalid': submitted && $v.newBooking.campId.$error }"
                  placeholder=""
                  :allow-empty="false"
                >
                  <template slot="singleRoom" slot-scope="{ type }">{{
                    type.name
                  }}</template>
                </multiselect>
                <div v-if="submitted && $v.newBooking.campId.$error" class="invalid-feedback">
                  <span v-if="!$v.newBooking.campId.required" >Champ Obligatoire.</span>
                </div>
              </div>
              <div class="col-md-4">
                <label for="">Bloc</label>
                <multiselect
                  v-model="newBooking.blocId"
                  :searchable="true"
                  track-by="id"
                  open-direction="bottom"
                  label="name"
                  :options="blocs"
                  @input="onBlocChange"
                  :class="{'is-invalid': submitted && $v.newBooking.blocId.$error }"
                  placeholder=""
                  :allow-empty="false"
                >
                  <template slot="singleRoom" slot-scope="{ type }">{{
                    type.name
                  }}</template>
                </multiselect>
                <div v-if="submitted && $v.newBooking.blocId.$error" class="invalid-feedback">
                  <span v-if="!$v.newBooking.blocId.required" >Champ Obligatoire.</span>
                </div>
              </div>

              <div class="col-md-4">
                <label for="">Chambre N°</label>
                <multiselect
                  v-model="newBooking.roomId"
                  :searchable="true"
                  track-by="id"
                  open-direction="bottom"
                  label="number"
                  :options="rooms"
                  :class="{'is-invalid': submitted && $v.newBooking.roomId.$error }"
                  placeholder=""
                  :allow-empty="false"
                >
                  <template slot="singleRoom" slot-scope="{ type }">{{
                    type.number
                  }}</template>
                </multiselect>
                <div v-if="submitted && $v.newBooking.roomId.$error" class="invalid-feedback">
                  <span v-if="!$v.newBooking.roomId.required" >Champ Obligatoire.</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 mt-2">
                <b-button @click="submitNewBooking" variant="primary float-right">
                  <i class="fas fa-save"></i> Enregistrer</b-button>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </Modal>

    <!-- CHECKIN MODAL -->
    <Modal
      v-model="showCheckInModal"
      modalClass="checkInModal fullWidthModal"
      title="EEEE"
    >
      <template v-slot:titlebar>
        <div class="vm-titlebar">
          <h3 class="vm-title">
            <img
              :src="
                require('@/assets/images/base/ops/hosting/check_in.png')
              "
              alt=""
            />
            <p>
              Check In Pour la réservation N°:
              <b>{{ bookingForm.ref }}</b>
            </p>
          </h3>
        </div>
      </template>
      <div class="">
        <div class="row bookingDataContainer">
          <div class="col-md-2">
            <label for="">Référence</label>
            <p>
              <b>{{ bookingForm.ref }}</b>
            </p>
          </div>
          <div class="col-md-2">
            <label for="">Date d'arrivé</label>
            <p>
              <b>{{ bookingForm.dateIn }}</b>
            </p>
          </div>
          <div class="col-md-2">
            <label for="">Date de départ</label>
            <p>
              <b>{{ bookingForm.dateOut }}</b> ({{
                bookingForm.nights
              }}
              Nuitées)
            </p>
          </div>

          <div class="col-md-2">
            <label for="">Occupant</label>
            <p>
              <b>{{ bookingForm.full_name }}</b>
            </p>
          </div>
          <div class="col-md-3">
            <label for="">Chambre Affectée</label> <br />
            <multiselect
              v-model="checkInModal.roomId"
              :searchable="true"
              track-by="id"
              open-direction="bottom"
              label="number"
              :options="rooms"
              placeholder=""
              :allow-empty="false"
              :class="{
                'is-invalid':
                  submitted && $v.checkInModal.roomId.$error,
              }"
            >
              <template slot="singleRoom" slot-scope="{ room }">{{
                room.roomName
              }}</template>
            </multiselect>
            <div
              v-if="submitted && $v.checkInModal.roomId.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.checkInModal.roomId.required"
                >Champ Obligatoire.</span
              >
            </div>
          </div>

          <!-- <div class="col-md-2">
            <label for="">Opération effectuée par:</label> <br />
            <a href="javascript:void(0)"
              >{{ user.first_name }} {{ user.last_name }}</a
            >
          </div> -->

          <div class="col-md-2">
            <label for="">Date / heure d'opération:</label> <br />
            <p>
              <b>{{ now() }}</b>
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <label for="subject">Commentaires</label>
            <textarea
              class="form-control"
              v-model="checkInModal.comments"
              cols="30"
              rows="4"
            ></textarea>
          </div>
        </div>
        <br />
        <div>
          <button class="btn btn-primary" @click="performCheckin">
            <i class="fas fa-save"></i>
            Enregistrer
          </button>
        </div>
      </div>
    </Modal>
    <!-- Check Out Modal -->
    <Modal
      v-model="showCheckOutModal"
      modalClass="checkInModal fullWidthModal"
    >
      <template v-slot:titlebar>
        <div class="vm-titlebar">
          <h3 class="vm-title">
            <img
              :src="
                require('@/assets/images/base/ops/hosting/check_out.png')
              "
              alt=""
            />
            <p>
              Check Out Pour la réservation N°:
              <b>{{ bookingForm.ref }}</b>
            </p>
          </h3>
        </div>
      </template>
      <div class="">
        <div class="row bookingDataContainer">
          <div class="col-md-2">
            <label for="">Référence</label>
            <p>
              <b>{{ bookingForm.ref }}</b>
            </p>
          </div>
          <div class="col-md-2">
            <label for="">Date d'arrivé</label>
            <p>
              <b>{{ bookingForm.dateIn }}</b>
            </p>
          </div>
          <div class="col-md-2">
            <label for="">Date de départ</label>
            <p>
              <b>{{ bookingForm.dateOut }}</b> ({{
                bookingForm.nights
              }}
              Nuitées)
            </p>
          </div>

          <div class="col-md-2">
            <label for="">Occupant</label>
            <p>
              <b>{{ bookingForm.full_name }}</b>
            </p>
          </div>
          <div class="col-md-3">
            <label for="">Chambre Affectée</label> <br />
            <p>
              Chambre N°: <b>{{ bookingForm.room.number }}</b>
            </p>
          </div>

          <!-- <div class="col-md-2">
            <label for="">Opération effectuée par:</label> <br />
            <a href="javascript:void(0)"
              >{{ user.first_name }} {{ user.last_name }}</a
            >
          </div> -->

          <div class="col-md-2">
            <label for="">Date / heure d'opération:</label> <br />
            <p>
              <b>{{ now() }}</b>
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <label for="subject">Clé remis</label> <br />
            <b-form-checkbox
              v-model="checkOutModal.keyReturned"
              style="display:inline-block;"
              switch
              class="mb-1"
            />
          </div>
          <div class="col-md-12">
            <label for="subject">Commentaires</label>
            <textarea
              class="form-control"
              v-model="checkOutModal.comments"
              cols="30"
              rows="4"
            ></textarea>
          </div>
        </div>
        <br />
        <div>
          <button class="btn btn-primary" @click="performCheckout">
            <i class="fas fa-save"></i>
            Enregistrer
          </button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Dataset from "vue-dataset/dist/es/Dataset.js";
import DatasetItem from "vue-dataset/dist/es/DatasetItem.js";
import DatasetShow from "vue-dataset/dist/es/DatasetShow.js";
import search from "./search";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";

import {
  fetchLifebasesApi,
  fetchRoomsByBlocListApi
} from "@/api/common";
export default {
  props: {
    refreshplannings: {
      type: Boolean,
      default: false,
    },
    searchResult: {},
  },
  components: {Multiselect, Dataset, DatasetItem, DatasetShow, search },
  import: {
    fetchLifebasesApi,
    fetchRoomsByBlocListApi
  },
  validations: {
    newBooking: {
      campId: { required },
      blocId: { required },
      roomId: { required },
    },

    checkInModal: {
      roomId: { required },
    },
  },
  data() {
    return {
      active: true,
      submitted: false,
      showCheckInModal: false,
      showCheckOutModal: false,
      plannings: [],
      camps: [],
      blocs: [],
      rooms: [],
      defaultShow: 5,
      selectedIndex: -1,
      showNewBookingModal: false,
      newBooking: {
        syncId: "",
        emp: "",
        campId: "",
        blocId: {},
        roomId: "",
        from: "",
        to: "",
        empNum: "",
      },
      checkInModal: {
        roomId: "",
        comments: "",
        bookingId: "",
        bookingUid: "",
      },
      checkOutModal: {
        keyReturned: false,
        room: "",
        comments: "",
        bookingId: "",
        bookingUid: "",
      },
      bookingForm: {
        companyId: "",
        visitorId: "",
        roomType: "",
        beneficiaryFullName: "",
        structName: "",
        dateIn: "",
        dateOut: "",
        beneficiaryType: "",
        beneficiaryId: "",
        nights: 0,
        attachment: "",
        structId: "",
        room: "",
        bloc: "",
        occupant: [],
        relocations: [],
        companyName: "",
        supplierId: "",
        comments: "",
        campId: "",
        visitor: {
          fullName: "",
          identityDocType: "",
          identityDocNo: "",
          comments: "",
        },
        keyStatus: "",
      },
      cols: [
        {
          name: "N° Employé",
          field: "",
          sort: "",
        },
        {
          name: "Nom",
          field: "",
          sort: "",
        },
        {
          name: "Prénom",
          field: "",
          sort: "",
        },
        {
          name: "Date d'arrivé",
          field: "",
          sort: "",
        },
        {
          name: "Date de départ",
          field: "",
          sort: "",
        },
        {
          name: "Actions",
          field: "",
          sort: "",
        },
      ],
    };
  },

  watch: {
    searchResult() {
      if (this.searchResult) {
        this.plannings = this.searchResult;
      }
    },
  },

  mounted() {
    this.refreshplanningsTable();
  },

  methods: {
    
    submitNewBooking(){
      this.submitted = true;
      this.$v.newBooking.$touch();
      if (!this.$v.newBooking.$invalid) {
        var loader = this.$loading.show();
        this.$http
          .post("/ops/booking/saveFromSchedule", this.newBooking)
          .then((res) => {
            loader.hide();
            var status = res.data.original.status;
            switch (status) {
              case 200:
                // this.events.showRelocationModal = false;
                this.$toast.success(res.data.original.msg);
                this.showNewBookingModal = false;
                this._clearNewBookingForm();
                break;

              case 500:
                this.$toast.warning(res.data.original.msg);
                break;
            }
          })
          .catch((error) => {
            loader.hide();
            this.$toast.error(error.message);
          })
          .finally(() => {
            loader.hide();
          });
      }
    },

    _clearNewBookingForm(){
      this.newBooking.syncId = "";
      this.newBooking.emp = "";
      this.newBooking.campId = "";
      this.newBooking.blocId = "";
      this.newBooking.roomId = "";
      this.newBooking.from = "";
      this.newBooking.to = "";
      this.newBooking.empNum = "";
    },

    onCampChange(){
      this.blocs = this.newBooking.campId.buildings;
    },

    onBlocChange(){
        var loader = this.$loading.show();
      fetchRoomsByBlocListApi(this.newBooking.blocId.id)
        .then((res) => {
          loader.hide();
          this.rooms = res.data.original.list;
        })
        .catch(() => {
          loader.hide();
        })
        .finally(() => {
          loader.hide();
        });
    },

    _fetchCampsList() {
      var loader = this.$loading.show();
      fetchLifebasesApi()
        .then((res) => {
          loader.hide();
          this.camps = res.data.original.list;
        })
        .catch(() => {
          loader.hide();
        })
        .finally(() => {
          loader.hide();
        });
      // bases
    },

    showNewBookindModal(row){
      this._fetchCampsList();
      this.newBooking.syncId = row.id;
      this.newBooking.empNum = row.emp_num;
      this.newBooking.emp = row.employee.last_name+ ' ' +row.employee.first_name;
      this.newBooking.from = row.date_from;
      this.newBooking.to = row.date_to;
      this.showNewBookingModal = true;
      
    },
    handlePerformBookingSearchEvent(event, page = 1) {
      var loader = this.$loading.show();
      var show = this.defaultShow;

      this.$http
        .post("/ops/booking/simpleSearch?show=" + show + "&page=" + page, {
          searchForm: event,
        })
        .then((res) => {
          loader.hide();
          this.bookings = res.data;
        })
        .catch((error) => {
          loader.hide();
          this.$toast.warning(error.message);
          // error.response.status Check status code
        })
        .finally(() => {
          loader.hide();
          //Perform action in always
        });
    },

    handleResetSearchFormEvent(event) {
      if (event) {
        this.bookings = [];
        this.refreshplanningsTable(1);
      }
    },
    handlePerformplanningsearchEvent(event, page = 1) {
      var loader = this.$loading.show();
      var show = this.defaultShow;

      this.$http
        .post("/ops/schedule/simpleSearch?show=" + show + "&page=" + page, {
          searchForm: event,
        })
        .then((res) => {
          loader.hide();
          this.plannings = res.data;
        })
        .catch((error) => {
          loader.hide();
          this.$toast.warning(error.message);
          // error.response.status Check status code
        })
        .finally(() => {
          loader.hide();
          //Perform action in always
        });
    },

    onShowChange() {},
    now() {
      return new Date().toLocaleString("en-GB", { timeZone: "UTC" });
    },

    refreshplanningsTable(page = 1) {
      var loader = this.$loading.show();
      var show = this.defaultShow;
      this.$http
        .post("/ops/schedule/paginate?show=" + show + "&page=" + page)
        .then((res) => {
          loader.hide();
          this.plannings = res.data;
        })
        .catch((error) => {
          loader.hide();
          this.$toast.warning(error.message);
          // error.response.status Check status code
        })
        .finally(() => {
          loader.hide();
          //Perform action in always
        });
    },
    ShowCheckinModal(row) {
      this.checkInModal.bookingId = row.checkin.id;
      this.checkInModal.bookingUid = row.checkin.uuid;
      this.bookingForm.ref = row.checkin.ref;
      this.bookingForm.dateIn = row.checkin.planned_arrival_date;
      this.bookingForm.nights = row.checkin.nights;
      this.bookingForm.full_name = row.full_name;
      
      // this.newBooking.blocId.id = row.bloc_id;
      // this.onBlocChange()
      this.rooms = [row.suggested_room];
      this.checkInModal.roomId = row.suggested_room;
      this.showCheckInModal = true;
      
    },
    ShowCheckOutModal(row) {
      this.checkOutModal.bookingId = row.checkout.id;
      this.checkOutModal.bookingUid = row.checkout.uuid;

      this.bookingForm.ref = row.checkout.ref;
      this.bookingForm.dateIn = row.checkout.real_arrival_date;
      this.bookingForm.dateOut = row.checkout.planned_departure_date;
      this.bookingForm.nights = row.checkout.nights;
      this.bookingForm.room = row.checkout.assigned_room[0];
      this.bookingForm.full_name = row.full_name;
      // console.log(this.bookingForm)
      this.showCheckOutModal = true;
    },
    performCheckin() {
      // add swal confirm
      // room required
      this.submitted = true;
      this.$v.checkInModal.$touch();
      if (!this.$v.checkInModal.$invalid) {
        var loader = this.$loading.show();
        this.$http
          .post("/ops/booking/checkin", this.checkInModal)
          .then((res) => {
            loader.hide();
            var status = res.data.status;
            switch (status) {
              case 200:
                this.showCheckInModal = false;
                this.$toast.success(res.data.msg);
                this.$emit("checkInPerformed", true);
                this.refreshplanningsTable(1);
                break;

              case 500:
                this.$toast.warning(res.data.msg);
                break;
            }
          })
          .catch((error) => {
            loader.hide();
            this.$toast.error(error.message);
          })
          .finally(() => {
            loader.hide();
          });
      }
    },
    performCheckout() {
      var loader = this.$loading.show();
      this.$http
        .post("/ops/booking/checkout", this.checkOutModal)
        .then((res) => {
          loader.hide();
          var status = res.data.status;
          switch (status) {
            case 200:
              this.$toast.success(res.data.msg);
              this.showCheckOutModal = false;
              this.$emit("checkOutPerformed", true);
              this.refreshplanningsTable(1);
              break;

            case 500:
              this.$toast.warning(res.data.msg);
              break;
          }
        })
        .catch((error) => {
          loader.hide();
          this.$toast.error(error.message);
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
};
</script>
