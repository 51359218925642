<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import BookingsList from "./components/BookingsList";

import { fetchLifebasesApi } from "@/api/common";
// import EmployeesPlanning from '../components/employeesPlanning.vue';
export default {
  page: {
    title: "Planning",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    BookingsList,
  },
  data() {
    return {
      title: "Planning",
      resetAddBtn: false,
      singleBookingData: null,
      bookings_SearchResult: [],
      
      items: [
        {
          text: "Hébergement",
          href: "javascript:void(0)",
        },
        {
          text: "Planning des réservations",
          active: true,
        },
      ],
    };
  },

  methods: {
    searchBookings() {
      var loader = this.$loading.show();
      var selectedCamps = "";
      this.$http
        .post("/ops/booking/searchBookings", {
          from: this.date.start,
          to: this.date.end,
          camps: this.bases,
        })
        .then((res) => {
          loader.hide();
          var status = res.data.status;
          var list = res.data.list;
          switch (status) {
            case 200:
              this.bookings_SearchResult = list;
              break;

            case 500:
              loader.hide();

              this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          loader.hide();

          this.$toast.error(error.message);
        })
        .finally(() => {});
    },
    
    filterBookings(stat) {
      var loader = this.$loading.show();
      this.$http
        .post("/ops/booking/filterBookings", {
          stat: stat,
        })
        .then((res) => {
          loader.hide();
          this.bookings_SearchResult = res.data;
        })
        .catch((error) => {
          loader.hide();

          this.$toast.error(error.message);
        })
        .finally(() => {});
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader>
      <template v-slot:datePicker></template>
    </PageHeader>

    <bookings-list :searchResult="bookings_SearchResult"/>
  </Layout>
</template>
